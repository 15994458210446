class HomeClients extends Component {
    constructor(el) {
        super(el);
        this.swiperEl = el.querySelector('[data-swiper]')
        this.swiper = null
        this.addEventListeners()
    }

    addEventListeners() {
        document.addEventListener('DOMContentLoaded', ()=> {
            if (this.swiperEl) {
                this.swiper = new Swiper(this.swiperEl, {
                    slidesPerView: 1,
                    autoplay: {
                      delay:  5000
                    },
                    breakpoints: {
                        500: {
                            slidesPerView: 2
                        },
                        750: {
                            slidesPerView: 3
                        },
                        1000: {
                            slidesPerView: 4
                        },
                        1250: {
                            slidesPerView: 5
                        },
                        1500: {
                            slidesPerView: 6
                        }
                    }
                })

            }
        })
    }
}